import React, {useEffect, useState} from 'react';
import { Box, Paper } from '@material-ui/core';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import { faFilePdf, faDownload} from '@fortawesome/free-solid-svg-icons';
import { useHistory} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import imgVentilador from '../Asset/ventiladorIcono.png';
import imgBorneras from '../Asset/bornerasIconos.png';
import imgEscobilla from '../Asset/escobillaIcono.png';
import imgAnillosPortaescobillas from '../Asset/anillosPortaescobillas.png';
import imgColector from '../Asset/colectorIcono.png';
import imgAutomatico from '../Asset/automaticoIcono.png';
import imgTrencilla from '../Asset/trencillaIcono.png';
import imgCaperuza from '../Asset/CaperuzaIcono.png';
import imgContactos from '../Asset/contactosIcono.JPG';
import imgCunas from '../Asset/cunas.png';
import imgResortes from '../Asset/resortes.png';
import imgCatalogo from '../Asset/catalogo.jpg';


import WhatsappWeb from '../components/redesSociales'
import MostrarPlanoPdf from '../components/modalPDF';

import pdfColector from '../Asset/pdf/colector.pdf';
import pdfAnilloRozante from '../Asset/pdf/anilloRozante.pdf';
import pdfTrencilla from '../Asset/pdf/trencilla.pdf';
import pdfCatalogoTecnigal from '../Asset/pdf/catalogo_tecnigal.pdf';

import Mapa from '../components/ubicacionMapa';
import QuienesSomos from '../components/quienesSomos';
import FichasTecnicas from '../components/descragasFichasTecnicas/fichasTecnicas';
import Isovolta from '../components/Isovolta/Isovolta';
import RedesSoiales from '../components/redes Sociales/redesSociales';
import GaleriaImagenes from '../components/galeriaImg/index';

import BotonChat from '../components/chatOnline/chatOnline';
import AceptarpoliticasCokkies from '../components/AceptarpoliticasCokkies';


const telefonos=['3217740000','3217741111','3217742222'];

function Inicio() {
    let history = useHistory();
    const [telefonoWhatsapp, setTelefonoWhatsapp] = useState(null)

    const [tituloModalPDF, setTituloModalPDF] = useState('')
    const [arcghivoPDF, setArcghivoPDF] = useState('')
    const [showModalPDF, setShowModalPDF] = useState(false)
    const [showPoliticasCokies, setShowPoliticasCokies] = useState(true)

    useEffect(() => {
        cargaInicial()
        return () => {
            cargaInicial()
        }
    }, [])

    const cargaInicial=async()=>{
        const random = telefonos[Math.floor(Math.random()*telefonos.length)]
        setTelefonoWhatsapp(random)
    }
 
    const mostrarModalPlanoPdf=(titulo,url)=>{
        setTituloModalPDF(titulo)
        setArcghivoPDF(url)
        setShowModalPDF(true)
    }

    const cerraModalPDF=()=>{
        setShowModalPDF(false)
        setTituloModalPDF('')
        setArcghivoPDF('')
    }

    const redireccionar=(num)=>{
        switch (num) {
            case 1:
                history.push('/prod/ventiladores')
            break;
            case 2:
                history.push('/prod/borneras')
            break;
            case 3:
                history.push('/prod/escobillas')
            break;
            case 4:
                history.push('/prod/automaticos')
            break;  
            default:
                history.push('/prod/automaticos')
            break;                        
        }
    }

    return (
        <div className="mt-3 container">

            <div className="row">

                <QuienesSomos/>

                {/* ventiladores en aluminio */}
                <div className="col-sm-3 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 agrandar transicion">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" style={{maxHeight:'150px', height:'150px', position:'relative'}} onClick={()=>{redireccionar(1)}}>  
                            <img src={imgVentilador} alt="Ventilador icono" title="Ventilador icono" height="100%" />
                        </div>
                        <Divider className="border-bottom border-dark"/>
                        <ListItem button divider>
                            <div className="row" style={{maxHeight:'180px', height:'180px'}}>
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Ventiladores en Aluminio</b>
                                </div>
                                <p style={{textAlign:'justify'}}>
                                    Utilizados para refrigeración de motores, asi como en extractores de aire y ventiladores industriales. Contamos con multiples referencias 
                                    en materiales plásticos y aluminio.  
                                </p>
                            </div>
                        </ListItem>   

                        <CardActions>
                            {/* <Button size="large" onClick={()=>mostrarGaleriaImagenes('Ventiladores')}>
                                <FontAwesomeIcon icon={faImage} className="text-primary" style={{fontSize:'22px'}}/>
                            </Button>                              */}
                            <WhatsappWeb mensaje='Desde la web quiero saber más sobre los Ventiladores' telefonoWhatsapp={telefonoWhatsapp}/>                        
                        </CardActions>             
                    </Paper>
                    </Box>
                </div>

                {/* borneras */}
                <div className="col-sm-3 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 agrandar transicion">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" style={{maxHeight:'150px', height:'150px', position:'relative'}} onClick={()=>{redireccionar(2)}}>
                            <img src={imgBorneras} alt="Borneras icono" title="Borneras icono" width="100%" />
                        </div>
                        <Divider className="border-bottom border-dark"/>
                        <ListItem button divider>
                            <div className="row" style={{maxHeight:'180px', height:'180px'}}>
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Borneras</b>
                                </div>
                                <p style={{textAlign:'justify'}}>
                                    Llamadas también regletas de conexión, por medio de ellas se conectan las diferentes señales provenientes de un motor o equipo eléctrico
                                </p>
                            </div>
                        </ListItem>   

                        <CardActions>
                            {/* <Button size="large" onClick={()=>mostrarModalPlanoPdf('Plano de Bornera',pdfBorneras)}>
                                <FontAwesomeIcon icon={faFilePdf} className="text-primary" style={{fontSize:'22px'}}/>
                            </Button>
                            <Button size="large">
                                <a href={pdfBorneras} target="_blank" rel="noopener noreferrer" download="bornera.pdf"> 
                                    <FontAwesomeIcon icon={faDownload} className="text-primary" style={{fontSize:'22px'}}/>
                                </a>                                
                            </Button>
                            <Button size="large" onClick={()=>mostrarGaleriaImagenes('Borneras')}>
                                <FontAwesomeIcon icon={faImage} className="text-primary" style={{fontSize:'22px'}}/>
                            </Button>                               */}
                            <WhatsappWeb mensaje='Desde la web quiero saber más sobre las borneras' telefonoWhatsapp={telefonoWhatsapp}/>                        
                        </CardActions>                 
                    </Paper>
                    </Box>
                </div>
                
                {/* Escobillas */}
                <div className="col-sm-3 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 agrandar transicion">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" style={{maxHeight:'150px', height:'150px', position:'relative'}}  onClick={()=>{redireccionar(3)}}>
                            <img src={imgEscobilla} alt="Escobilla icono" title="Escobilla icono" width="100%" />
                        </div>
                        <Divider className="border-bottom border-dark"/>
                        <ListItem button divider>
                            <div className="row" style={{maxHeight:'180px', height:'180px', overflow:'auto'}}>
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Escobillas</b>
                                </div>
                                <p style={{textAlign:'justify'}}>
                                    {/* Son piezas conductoras utilizadas para transmisión de corriente a través de los portaescobillas, anillos rozantes y colectores en motores, 
                                    generadores, electrodomésticos.   */}
                                    Las escobillas son una de las partes fundamentales en el funcionamiento de un motor eléctrico. Se pueden definir como el elemento que ejerce de conexión
                                     eléctrica entre la parte fija y la giratoria dentro de un motor eléctrico (rotor).
                                </p>
                            </div>
                        </ListItem>   

                        <CardActions>
                            {/* <Button size="large" onClick={()=>mostrarGaleriaImagenes('Escobillas')}>
                                <FontAwesomeIcon icon={faImage} className="text-primary" style={{fontSize:'22px'}}/>
                            </Button>                               */}
                            <WhatsappWeb mensaje='Desde la web quiero saber más sobre las Escobillas' telefonoWhatsapp={telefonoWhatsapp}/>                        
                        </CardActions>                 
                    </Paper>
                    </Box>
                </div>

                {/* automaticos */}
                <div className="col-sm-3 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 agrandar transicion">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" style={{maxHeight:'150px', height:'150px', position:'relative'}} onClick={()=>{redireccionar(4)}}>
                            <img src={imgAutomatico} alt="Automatico icono" title="Automatico icono"  style={{maxHeight:"100%", maxWidth:'100%'}} />
                        </div>
                        <Divider className="border-bottom border-dark"/>
                        <ListItem button divider>
                            <div className="row" style={{maxHeight:'180px', height:'180px'}}>
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Automáticos y/o Platineras</b>
                                </div>
                                <p style={{textAlign:'justify'}}>
                                    Hace Parte del circuito de arranque de los motores monofásicos. Su función es desconectar el devanado de arranque, una vez el motor llegue 
                                    a su velocidad nominal
                                </p>
                            </div>
                        </ListItem>   

                        <CardActions>
                            {/* <Button size="large" onClick={()=>mostrarGaleriaImagenes('Automaticos')}>
                                <FontAwesomeIcon icon={faImage} className="text-primary" style={{fontSize:'22px'}}/>
                            </Button>                               */}
                            <WhatsappWeb mensaje='Desde la web quiero saber más sobre los automaticos' telefonoWhatsapp={telefonoWhatsapp}/>                        
                        </CardActions>                 
                    </Paper>
                    </Box> 
                </div>                

                {/* colectores */}
                <div className="col-sm-6 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 transicion">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" style={{maxHeight:'150px', height:'150px', position:'relative'}}>
                            <img src={imgColector} alt="Colector icono" title="Colector icono" width="170px" />
                        </div>
                        <Divider className="border-bottom border-dark"/>
                        <ListItem button divider>
                            <div className="row" style={{maxHeight:'180px', height:'180px', overflow:'auto'}}>
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Colectores</b>
                                </div>
                                <p style={{textAlign:'justify'}}>
                                    El colector de delgas es el encargado de recolectar el voltaje generado y a través de las escobillas permite extraer dicha tensión; esta constituido por un número determinado 
                                    de láminas de cobre llamadas delgas, las cuales quedan aisladas entre sí mediante láminas.
                                    {/* En función del tipo de trabajo y las características medio-ambientales, cada calidad de escobillas forma una pátina particular, 
                                    compuesta básicamente por partículas de grafito, oxido de cobre Cu I y CU II así como una película de agua absorbida.
                                    La pátina es un elemento que ayuda para determinar el comportamiento correcto o incorrecto de cualquier máquina. 
                                    Hay aspectos propios de superficie de colector. Son utilizadas por fabricantes y usuarios de escobillas para analizar el funcionamiento de las mismas.<br/> */}
                                </p>
                            </div>
                            {/* En función del tipo de trabajo y las características medio-ambientales, cada calidad de escobillas forma una pátina característica(particular), 
                                    compuesta básicamente por partículas de grafito, oxido de cobre Cu I y CU II así como una película de agua absorbida.
                                    La pátina es un elemento de gran ayuda para determinar el comportamiento correcto o incorrecto de cualquier máquina. 
                                    Hay aspectos propios de superficie de colector. Son utilizadas por fabricantes y usuarios de escobillas para analizar el funcionamiento de las mismas.<br/> */}
                        </ListItem>   

                        <CardActions>
                            <Button size="large" onClick={()=>mostrarModalPlanoPdf('Plano de colector',pdfColector)}>
                                <FontAwesomeIcon icon={faFilePdf} className="text-primary" style={{fontSize:'22px'}}/>
                            </Button>
                            <Button size="large">
                                <a href={pdfColector} target="_blank" rel="noopener noreferrer" download="colector.pdf"> 
                                    <FontAwesomeIcon icon={faDownload} className="text-primary" style={{fontSize:'22px'}}/>
                                </a>                                
                            </Button>                           
                            <WhatsappWeb mensaje='Desde la web quiero saber mas sobre los colectores' telefonoWhatsapp={telefonoWhatsapp}/>                        
                        </CardActions>                
                    </Paper>
                    </Box> 
                </div>                
       
                {/* Anillos Rozantes */}
                <div className="col-sm-6 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 transicion">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" style={{maxHeight:'150px', height:'150px', position:'relative'}}>
                            <img src={imgAnillosPortaescobillas} alt="Anillos y Portaescobillas icono" title="Anillos y Portaescobillas icono" style={{maxHeight:"100%", maxWidth:'100%'}} />
                        </div>
                        <Divider className="border-bottom border-dark"/>
                        <ListItem button divider>
                            <div className="row" style={{maxHeight:'180px', height:'180px'}}>
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Anillos Y Portaescobillas</b>
                                </div>
                                <p style={{textAlign:'justify'}}>
                                Sirven para tomar o inyectar corriente del rotor de un motor o generador. Su fabricación es a base de resina epóxica y fibra de vidrio, con dos, tres o más anillos, (bipolares, tripolares o multipolares). En casos particulares se pueden reparar
                                </p>
                            </div>
                        </ListItem>   

                        <CardActions>
                            <Button size="large" onClick={()=>mostrarModalPlanoPdf('Plano de colector',pdfAnilloRozante)}>
                                <FontAwesomeIcon icon={faFilePdf} className="text-primary" style={{fontSize:'22px'}}/>
                            </Button>
                            <Button size="large">
                                <a href={pdfAnilloRozante} target="_blank" rel="noopener noreferrer" download="colector.pdf"> 
                                    <FontAwesomeIcon icon={faDownload} className="text-primary" style={{fontSize:'22px'}}/>
                                </a>                                
                            </Button>                          
                            <WhatsappWeb mensaje='Desde la web quiero saber mas sobre los anillos rozantes' telefonoWhatsapp={telefonoWhatsapp}/>                      
                        </CardActions>                    
                                    
                    </Paper>
                    </Box>   
                </div>

                {/* trencillas flexibles */}
                <div className="col-sm-6 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 transicion">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" style={{maxHeight:'150px', height:'150px'}}>
                            <img src={imgTrencilla} alt="Trencillas puesta tierra icono" title="Trencillas puesta tierra icono" style={{maxHeight:"100%", maxWidth:'100%'}} />
                        </div>
                        <Divider className="border-bottom border-dark"/>
                        <ListItem button divider>
                            <div className="row" style={{maxHeight:'220px', height:'220px', overflow:'auto'}}>
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Trencillas Flexibles</b>
                                </div>
                                <p style={{textAlign:'justify'}}>
                                    {/* Son trencillas de multifilamentos de cobre desnudo muy finos, con terminales de cobre en sus extremos. Se utilizan como elemento de conexión flexible para conducción de corriente.
                                    Se tienen tipos de material diferentes calibres:<br/>
                                    -Cobre desnudo<br/>
                                    -Cobre estañado<br/>
                                    La trencilla flexible es útil en la conexión de mangos móviles de interruptores y para aterrizar piezas móviles o sujetas a vibraciones y/o movimientos longitudinales y laterales.
                                    La trencilla de cobre estañada retarda la corrosión en ambientes contaminados. */}
                                    Las trenzas de cobre o conexiones flexibles de cobre son una solución para aplicaciones en instalaciones  eléctricas que están sometida a fuertes vibraciones, ya que la trenza absorbe la vibración 
                                    e impide su propagación. Se fabrican en cable trenzado de cobre o con cables recubiertos en estaño para mitigar ambientes contaminados. Fabricadas con plano, muestra fisica o de acuerdo a la necesitad
                                     se diseñan para transferir la cantidad de flujo de corriente.
                                </p>
                            </div>
                        </ListItem>   

                        <CardActions>
                            <Button size="large" onClick={()=>mostrarModalPlanoPdf('Plano de trencilla',pdfTrencilla)}>
                                <FontAwesomeIcon icon={faFilePdf} className="text-primary" style={{fontSize:'22px'}}/>
                            </Button>
                            <Button size="large">
                                <a href={pdfTrencilla} target="_blank" rel="noopener noreferrer" download="trencilla.pdf"> 
                                    <FontAwesomeIcon icon={faDownload} className="text-primary" style={{fontSize:'22px'}}/>
                                </a>                                
                            </Button>                           
                            <WhatsappWeb mensaje='Desde la web quiero saber mas sobre las Trencillas' telefonoWhatsapp={telefonoWhatsapp}/>                        
                        </CardActions>                
                    </Paper>
                    </Box> 
                </div>

                {/* Contactos Electricos */}
                <div className="col-sm-6 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 transicion">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" 
                        style={{backgroundImage:`url(${imgContactos})`, maxHeight:'150px', height:'150px', backgroundPosition:'center', backgroundSize:'100%', backgroundRepeat:'no-repeat'}}>
                            {/* <img src={imgCaperuza} alt="CAPERUZA icono" title="CAPERUZA icono" width="130px" /> */}
                        </div>
                        <Divider className="border-bottom border-dark"/>
                        <ListItem button divider>
                            <div className="row" style={{maxHeight:'220px', height:'220px', overflow:'auto'}}>
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Contactos Eléctricos</b>
                                </div>
                                <p style={{textAlign:'justify'}}>
                                    Los contactos eléctricos son piezas para contactores, arrancadores, suiches, compensadores e interruptores, con o sin recubrimiento de materiales nobles.
                                    El buen desempeño y larga duración de sus contactos eléctricos lo determina la calidad de los materiales utilizados en su elaboración o reparación.
                                    Los contactos por su trabajo se ven sometidos a un continuo desgaste durante las maniobras de cierre y apertura del circuito y como consecuencia de ello, 
                                    se presenta la necesidad de cambiarlos o repararlos frecuentemente, lo que constituye el principal costo del mantenimiento de los aparatos eléctricos.
                                </p>
                            </div>
                        </ListItem>   

                        <CardActions>                           
                            <WhatsappWeb mensaje='Desde la web quiero saber mas sobre los Contactos' telefonoWhatsapp={telefonoWhatsapp}/>                          
                        </CardActions>               
                    </Paper>
                    </Box>              
                </div>

                {/* Cuñas */}
                <div className="col-sm-6 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 transicion">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" 
                        style={{backgroundImage:`url(${imgCunas})`, maxHeight:'150px', height:'150px', backgroundPosition:'center', backgroundSize:'100%', backgroundRepeat:'no-repeat'}}>
                            {/* <img src={imgCaperuza} alt="CAPERUZA icono" title="CAPERUZA icono" width="130px" /> */}
                        </div>
                        <Divider className="border-bottom border-dark"/>
                        <ListItem button divider>
                            <div className="row" style={{maxHeight:'220px', height:'220px', overflow:'auto'}}>
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Cuñas</b>
                                </div>
                                <p style={{textAlign:'justify'}}>
                                    Las cuñas son Utilizadas en el recuñado de ranuras en los estatores de generadores de centrales hidroeléctricas y motores eléctricos de corriente directa. 
                                    El material para las cuñas este fabricado a base de estratificados de tejidos de vidrio y resina epóxy (Norma G-11), el cual posee excelentes propiedades 
                                    dieléctricas y mecánicas operando a temperaturas clase F (155 °C). Existen diferentes tipos de cuñas, para lo cual se requiere de plano o muestra para su fabricación.
 
                                </p>
                            </div>
                        </ListItem>   

                        <CardActions>
                            {/* <Button size="large" onClick={()=>mostrarGaleriaImagenes('Cuñas')}>
                                <FontAwesomeIcon icon={faImage} className="text-primary" style={{fontSize:'22px'}}/>
                            </Button>                             */}
                            <WhatsappWeb mensaje='Desde la web quiero saber mas sobre las Cuñas' telefonoWhatsapp={telefonoWhatsapp}/>                          
                        </CardActions>               
                    </Paper>
                    </Box>              
                </div>

                {/* Resortes */}
                <div className="col-sm-6 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 transicion">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" 
                        style={{backgroundImage:`url(${imgResortes})`, maxHeight:'150px', height:'150px', backgroundPosition:'center', backgroundSize:'100%', backgroundRepeat:'no-repeat'}}>
                            {/* <img src={imgCaperuza} alt="CAPERUZA icono" title="CAPERUZA icono" width="130px" /> */}
                        </div>
                        <Divider className="border-bottom border-dark"/>
                        <ListItem button divider>
                            <div className="row" style={{maxHeight:'220px', height:'220px', overflow:'auto'}}>
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Resortes</b>
                                </div>
                                <p style={{textAlign:'justify'}}>
                                    {/* Son fabricados con diferentes materiales y diversos calibres de flejes. Tienen varios dobleces y toman formas complicadas por lo que el término “resortes planos o de fleje” 
                                    nos dan una descripción exacta. Sin embargo, sus usos pueden ir más allá de las funciones de los resortes. Se pueden utilizar en la conducción de electricidad, sostener 
                                    alguna parte, actuar como seguro o como sistema de presión para las escobillas. Cada resorte es diseñado a la medida para un uso especifico por que no existen diseños estándar 
                                    y requieren de la fabricación de herramientas especiales, por esto se pueden fabricar pequeñas series.  */}
                                    Fabricados con aceros SAE 1070-1090, en diversos calibres, estos pueden tener infinidad de geometrias de acuerdo a la aplicacion. Empleados como elemento para generar presion y 
                                    desplazamiento de elementos; su caracteristica principal se basa en la memoria que conserva el resorte despues de realizar el tratamiento termico. Fabricados a partir de muestras 
                                    fisicas o plano suministrados por el cliente
                                </p>
                            </div>
                        </ListItem>   

                        <CardActions>
                            {/* <Button size="large" onClick={()=>mostrarGaleriaImagenes('Cuñas')}>
                                <FontAwesomeIcon icon={faImage} className="text-primary" style={{fontSize:'22px'}}/>
                            </Button>                             */}
                            <WhatsappWeb mensaje='Desde la web quiero saber mas sobre las Cuñas' telefonoWhatsapp={telefonoWhatsapp}/>                        
                        </CardActions>               
                    </Paper>
                    </Box>              
                </div>

                {/* Caperuzas */}
                <div className="col-sm-3 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 agrandar transicion">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" style={{maxHeight:'150px', height:'150px', position:'relative'}}>
                            <img src={imgCaperuza} alt="CAPERUZA icono" title="CAPERUZA icono"  style={{maxHeight:"100%", maxWidth:'100%'}} />
                        </div>
                        <Divider className="border-bottom border-dark"/>
                        <ListItem button divider>
                            <div className="row" style={{maxHeight:'180px', height:'180px'}}>
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Caperuzas</b>
                                </div>
                                <p style={{textAlign:'justify'}}>
                                    {/* Las caperuzas son protectores o carcazas utilizadas para motores, se instalan al lado del ventilador del motor.    */}
                                    Las caperuzas o guardas sirven como protección al ventilador de los motores electricos; adicionalmente actúan como elemento 
                                    para recircular el aire y lograr mantener refrigerado el sistema. Se fabrican en lamina de acero y plasticas.
                                </p>
                            </div>
                        </ListItem>   

                        <CardActions>                          
                            <WhatsappWeb mensaje='Desde la web quiero saber mas sobre las caperuzas' telefonoWhatsapp={telefonoWhatsapp}/>                        
                        </CardActions>                
                    </Paper>
                    </Box>                                                                                                
                </div>                

                {/* Catalogo en liea */}
                <div className="col-sm-3 col-12 col-sx-12">
                    <Box width={12/12} className="p-3 agrandar transicion" title="Catalogo en linea">
                    <Paper elevation={12}>
                        <div className="p-2 text-center" 
                        style={{backgroundImage:`url(${imgCatalogo})`, maxHeight:'350px', height:'350px', backgroundPosition:'bottom', backgroundSize:'100%', backgroundRepeat:'no-repeat'}}>
                            {/* <img src={imgCaperuza} alt="CAPERUZA icono" title="CAPERUZA icono" width="130px" /> */}
                        </div>
                        <Divider className="border-bottom border-dark"/>  
                        <CardActions>
                            <Button size="large" onClick={()=>mostrarModalPlanoPdf('Catalogo Tecnigal',pdfCatalogoTecnigal)}>
                                <FontAwesomeIcon icon={faFilePdf} className="text-primary" style={{fontSize:'22px'}}/>
                            </Button>
                            <Button size="large">
                                <a href={pdfCatalogoTecnigal} target="_blank" rel="noopener noreferrer" download="catalogo_tecnigal.pdf"> 
                                    <FontAwesomeIcon icon={faDownload} className="text-primary" style={{fontSize:'22px'}}/>
                                </a>                                
                            </Button>                         
                            <WhatsappWeb mensaje='Catalogo completo de TECNIGAL LTDA' telefonoWhatsapp={telefonoWhatsapp}/>                       
                        </CardActions>               
                    </Paper>
                    </Box>              
                </div>
            </div>

            {/* ISOVOLTA INFORMACION */}
            <Box width={12 / 12} className="transicion">
                <Paper elevation={12}>           
                    <Isovolta/>
                </Paper>
            </Box>                    

            {/* Fichas tecnicas area de descraga */}
            <Box width={12 / 12} className="transicion">
                <Paper elevation={12}>           
                    <FichasTecnicas/>
                </Paper>
            </Box>                    

            {/* ubicanos en le mapa */}
            <Box width={12 / 12} className="transicion">
                <Paper elevation={12}>
                    <Mapa/>
                </Paper>
            </Box>

            {/* galeria de imagenes */}
            <Box width={12 / 12} className="transicion">
                <Paper elevation={12}>
                    <GaleriaImagenes/>
                </Paper>
            </Box>

            <div className="col-sm-12 col-12 col-sx-12">
                <RedesSoiales/>
            </div>

            <BotonChat/>
            {showPoliticasCokies ?
                <AceptarpoliticasCokkies setShowPoliticasCokies={setShowPoliticasCokies}/>
                :
                null
            }
            <MostrarPlanoPdf titulo={tituloModalPDF} arcghivoPDF={arcghivoPDF} show={showModalPDF} handleClose={cerraModalPDF}/>
        </div>
    );
}

export default Inicio;