import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import Button from '@material-ui/core/Button';

export default function redesSociales({mensaje,telefonoWhatsapp}) {
    const quitar = ()=>{
        return mensaje.replace(/\s+/g, '%20')
    }
    return (
        <Button size="large" className="ms-auto text-success">
            <a target='_blank' rel="noreferrer" href={`https://api.whatsapp.com/send?phone=57${telefonoWhatsapp}&text=${quitar()}`}>
                <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: '22px' }} />
            </a>
        </Button>
    )
}
